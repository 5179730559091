import React from 'react'
import './Section5.css'
import { CiCircleCheck } from "react-icons/ci";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { CiMedicalClipboard } from "react-icons/ci";
import { FaArrowDown } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";


const Section5 = () => {
  return (
    <>
    <div className='sectionBg'>

    
    <div className='section5'>

        <div className="section5-content container">
            <h1>Getting started with us is as easy as 1-2-3.</h1> 
            <p>
            Our dedicated team is here to guide you through every step of the process, ensuring a smooth transition into a healthier, more stable life. 
            </p>
        </div>

            <div className='section5Icons container'>  
                <div className='section5FlexItem'>
                  <div className='section5Icon'>
                  <IoChatbubbleEllipsesOutline />

                  </div>
                  <h1>Reach Out to Us</h1>
                  <p>Contact our admissions team to discuss your needs and how we can help.</p>
                  <div className='arrowDown'><h2><FaArrowDown /></h2></div>
                </div>

                <div className='arrow show'>
                  <h2><HiOutlineArrowLongRight /></h2>
                </div>

                <div className='section5FlexItem'>
                  <div className='section5Icon'>
                  <CiMedicalClipboard />

                  </div>
                  <h1>Personalized Assessment</h1>
                  <p>We take into account your medical history, personal preferences, and any other relevant factors to ensure the best possible care.</p>
                  <div className='arrowDown'><h2><FaArrowDown /></h2></div>
                  <div className='show'>
                  <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/contact'>
                      <button>Schedule Admission</button>
                      </Link>
                  </LinkRoll>


                  </div>
                </div>

                <div className='arrow show'>
                  <h2><HiOutlineArrowLongRight /></h2>
                </div>


                <div className='section5FlexItem'>
                  <div className='section5Icon'>
                  <CiCircleCheck />

                  </div>
                  <h1> Begin Your Recovery Journey</h1>
                  <p> Our team of experts will support you through every stage of your recovery</p>

                  <div className='show hide'>
                  <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to='/contact'>
                        <button>Schedule Admission</button>
                      </Link>
                  </LinkRoll>

                  </div>

                </div>

            </div>




            {/* <div className='section5fFooter container'>
              <p>
                At Rocklin Rehab, we offer different levels of
                care based on each individual’s needs. Our programs consist of an
                intensive outpatient program (IOP) and a more demanding and
                structured partial hospitalization program (PHP). <br /> <br />
                You can be confident that our caring and professional staff will
                recommend the right level of care for you or your loved one.
              </p>
            </div> */}

        </div>

        </div>
    </>
  )
}

export default Section5
