import React from 'react'
import './Section1.css'
import img1 from "../../assets/therapy-session-young-man.webp";


const Section1 = () => {
  return (
    <>

    <div className='section1'>
        <div className="section1-content container">
          <div className='section1-left'>
                <h1>Welcome To Rocklin Rehab</h1>
                <hr />
                <p>
                At Rocklin Rehab, we are dedicated to providing compassionate and comprehensive care to support your journey towards recovery. Our state-of-the-art facility and experienced team of professionals are committed to helping you achieve your wellness goals. Whether you are seeking treatment for substance abuse, mental health issues, or physical rehabilitation, we tailor our programs to meet your unique needs. Join us at Rocklin Rehab and take the first step towards a healthier, happier future. Your path to recovery starts here.
                </p>
          </div>

          <div className='section1-right'>
            <img src={img1} alt="Calm man in front of the sea" loading="lazy" />
          </div>

        </div>
    </div>
    </>
  )
}

export default Section1
