import React from 'react'
import InsuranceSection from '../insurance/InsuranceSection';
import ContactForm2 from '../contact/Contact2';
import MissionHome from '../mission/MissionHome';
import TherapySA from '../therapySA/TherapySA';
import TherapyMH from '../therapyMH/TherapyMH';
import Section1 from '../section1/Section1';
import Section2 from '../section2/Section2';
import Section3 from '../section3/Section3';
import Cards from '../servicesCards/Cards';

import Section5 from '../section5/Section5';
// import WhatWeDo from '../section2/Section2';




const home = () => {
  return (
    <>
    <Section1 />
    <Section3 />

    <TherapyMH />
    <Section5 />


    {/* <WhatWeDo /> */}


    <Cards /> 
    <InsuranceSection />
    <TherapySA />

    <MissionHome />

    <Section2 />

    <ContactForm2 />

    </>
  )
}

export default home
