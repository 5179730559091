import React from 'react'
import './InsuranceSection.css'

import Aetna from '../../assets/Aetna.png'
import Beacon from '../../assets/Beacon.png'
import Bluecross from '../../assets/BlueCross.png'
import Cigna from '../../assets/Cigna.png'
import Horizon from '../../assets/Horizon.png'
import Anthem from '../../assets/Anthem.png'

import Therapist from '../../assets/woman_therapist.webp'



const InsuranceSection = () => {
    return (
        <>
        <div className='insuranceSection'>
                <div>
                        <div className='insuranceFlexBox container'>

                        <div className='insuranceFlex'>

                            <div className='insuranceLogos'>
                                <div className='insuranceSectionHeader'>
                                    <h1>Accepted Insurance Plans</h1>
                                    <p>Rocklin Rehab accepts a wide range of insurance plans to make your recovery journey as seamless as possible. We partner with major insurance providers, including but not limited to:</p>
                                </div>

                                <div className='insuranceThumbnails'>
                                    
                                <div className='insurance-thumbnail'>
                                    <img src={Aetna}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>

                                <div className='insurance-thumbnail'>
                                        <img src={Beacon}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>

                                <div className='insurance-thumbnail'>
                                        <img src={Bluecross}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>

                                <div className='insurance-thumbnail'>
                                        <img src={Cigna}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>

                                <div className='insurance-thumbnail'>
                                        <img src={Horizon}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>

                                <div className='insurance-thumbnail'>
                                        <img src={Anthem}  alt='Aetna Insurance Logo' loading='lazy'/>
                                </div>
                                </div>
                            </div>


                            <div className='insuranceFlexImg'>
                                <img src={Therapist} alt='Woman Therapist' loading='lazy' />
                            </div>

                        </div>

                    </div>
                </div>



            </div>




        </>
    )
}

export default InsuranceSection
